import { FC, Fragment, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { AvailableStagingsListContextProvider } from '../AvailableStagingsList/AvailableStagingsList.context'
import { ChangeRemunerationDrawerContextProvider } from '../ChangeRemunerationDrawer'
import { GalleryAdditionalVisualsContextProvider } from './contexts/GalleryAdditionalVisuals.context'
import { GalleryAssignmentContextProvider } from './contexts/GalleryAssignment.context'
import { GalleryCommentsContextProvider } from './contexts/GalleryComments.context'
import { GalleryConstantsContextProvider } from './contexts/GalleryConstants.context'
import { GalleryContextProvider } from './contexts/Gallery.context'
import { GalleryDealContextProvider } from './contexts/GalleryDeal.context'
import { GalleryDocumentsContextProvider } from './contexts/GalleryDocuments.context'
import { GalleryEditorProductContextProvider } from './contexts/GalleryEditorProduct.context'
import { GalleryOrderContextProvider } from './contexts/GalleryOrder.context'
import { GalleryProductContextProvider } from './contexts/GalleryProduct.context'
import { GalleryStagingStackProvider } from '../GalleryStagingFlow'
import { GalleryVirtualVisitContextProvider } from './contexts/GalleryVirtualVisit.context'
import { GalleryVisualSelectionContextProvider } from './contexts/GalleryVisualSelection.context'
import { GalleryVisualTypeContextProvider } from './contexts/GalleryVisualType.context'
import { GalleryVisualsContextProvider } from './contexts/GalleryVisuals.context'
import { GalleryVisualsMetaContextProvider } from './contexts/GalleryVisualsMeta.context'
import NotFound from 'components/pages/General/NotFound/NotFound'
import { PageTransition } from 'utils/animations'

export const Gallery: FC = () => {
  const { id } = useParams<{ id?: string }>()
  const assignmentId = useMemo(() => id || undefined, [id])

  if (!assignmentId) return <NotFound />

  return (
    <Fragment>
      {typeof assignmentId !== 'undefined' &&
        <GalleryConstantsContextProvider>
          <GalleryAssignmentContextProvider assignmentId={assignmentId}>
            <GalleryDealContextProvider assignmentId={assignmentId}>
              <GalleryDocumentsContextProvider assignmentId={assignmentId}>
                <GalleryVisualTypeContextProvider assignmentId={assignmentId}>
                  <GalleryProductContextProvider assignmentId={assignmentId}>
                    <GalleryOrderContextProvider assignmentId={assignmentId}>
                      <GalleryVisualsMetaContextProvider assignmentId={assignmentId}>
                        <GalleryVisualsContextProvider assignmentId={assignmentId}>
                          <GalleryVirtualVisitContextProvider assignmentId={assignmentId}>
                            <GalleryVisualSelectionContextProvider assignmentId={assignmentId}>
                              <GalleryAdditionalVisualsContextProvider assignmentId={assignmentId}>
                                <GalleryCommentsContextProvider assignmentId={assignmentId}>
                                  <GalleryEditorProductContextProvider assignmentId={assignmentId}>
                                    <ChangeRemunerationDrawerContextProvider>
                                      <GalleryStagingStackProvider>
                                        <AvailableStagingsListContextProvider>

                                          <GalleryContextProvider assignmentId={assignmentId}>
                                            <PageTransition>
                                              <Outlet></Outlet>
                                            </PageTransition>
                                          </GalleryContextProvider>

                                        </AvailableStagingsListContextProvider>
                                      </GalleryStagingStackProvider>
                                    </ChangeRemunerationDrawerContextProvider>
                                  </GalleryEditorProductContextProvider>
                                </GalleryCommentsContextProvider>
                              </GalleryAdditionalVisualsContextProvider>
                            </GalleryVisualSelectionContextProvider>
                          </GalleryVirtualVisitContextProvider>
                        </GalleryVisualsContextProvider>
                      </GalleryVisualsMetaContextProvider>
                    </GalleryOrderContextProvider>
                  </GalleryProductContextProvider>
                </GalleryVisualTypeContextProvider>
              </GalleryDocumentsContextProvider>
            </GalleryDealContextProvider>
          </GalleryAssignmentContextProvider>
        </GalleryConstantsContextProvider>
      }
    </Fragment>
  )
}
