import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { EnergyCertificateStepController } from './EnergyCertificateStep.controller'
import { FC } from 'react'
import { PurchaseFlowStepKey } from '../../../_main/contexts'

/**
 * @component
 * Page in PurchaseFlow step for energy certificate information.
 * 
 * @example 
 * <EnergyCertificateStep />
 */
export const EnergyCertificateStep: FC = () => {

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.ENERGY_CERTIFICATE_OF_NEED}
      isValid={true}
    >
      <EnergyCertificateStepController />
    </DynamicFlowStep>
  )
}
