import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { DUPLICATE_DEAL_CANCELLATION_ERROR_CODE, DealState } from 'constants/deal'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { AutoAwesomeOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { CopyGalleryLink } from 'components/pages/Gallery/CopyGalleryLink/CopyGalleryLink.module'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { OrderCancellationPopup } from 'components/common/OrderCancellationPopup'
import { PageActions } from 'components/common/Page/PageActions'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { isEditingCategory } from 'utils/validators'
import { stagesUnlockingOrderCancellation } from 'components/common/DealDetailPopup/DealDetailPopup'
import { useCancelOrder } from 'dataQueries'
import { useDynamicNow } from 'utils/hooks'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryProduct } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'
import { useGalleryVisualsMeta } from 'components/pages/Gallery/_main/contexts/GalleryVisualsMeta.context'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Client gallery page actions.
 * 
 * @example <ClientGalleryPageActions />
 */
export const ClientGalleryPageActions: FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()
  const cancelOrder = useCancelOrder()
  const dynamicNow = useDynamicNow()

  const { purchasedVisualsExist } = useGalleryVisualsMeta()
  const { dealData, shootingStart } = useGalleryDeal()
  const { isDocumentProduct } = useGalleryProduct()

  const {
    product,
    assignmentData,
    logGalleryEvent,
  } = useGalleryAssignment()

  const {
    hasUserActiveSubscription,
    clientData,
    currentUserWorkspace
  } = useUserData()

  const [disableButton, setDisableButton] = useState(true)
  const [isCancellationPopupOpen, setIsCancellationPopupOpen] = useState<boolean>(false)

  const handleGoToAIGenerator = useCallback(() => {
    if (!assignmentData?.id) return

    // ANALYTICS
    logAnalyticsEvent('Open Generate Text', {
      userEmail: clientData?.email,
      planId: currentUserWorkspace?.id,
      planName: currentUserWorkspace?.name,
      assignmentId: assignmentData.id,
      productCategory: product?.category,
      productType: product?.type,
      productKind: product?.kind,
    })

    navigate(
      Path.GALLERY_VISUALS_MARKETING.replace(':id', assignmentData.id.toString())
    )
  }, [assignmentData?.id, clientData?.email, currentUserWorkspace?.id, currentUserWorkspace?.name, navigate, product?.category, product?.kind, product?.type])

  const handleCheckSubscriptionClick = () => {
    if (!hasUserActiveSubscription) {
      logGalleryEvent(AnalyticsEvent.GALLERY_SAAS_UPSELL_TEXT_GENERATOR)
      externalNavigate(Path.PRICING)
    }
  }

  /**
   * Only show the cancel order button when:
   * order is not cancelled AND
   * order has a non-editing category AND
   * order has not started yet AND
   * order has no assignments past stage 4
  */
  const showCancelButton = useMemo(() => {
    if (!dealData) return false
    if (dealData.state === DealState.CANCELLED) return false
    if (cancelOrder.data?.data.response?.status === DUPLICATE_DEAL_CANCELLATION_ERROR_CODE) return false
    if (cancelOrder.isSuccess) return false
    if (isEditingCategory(dealData.productCategory)) return false
    if (!!shootingStart && dynamicNow.isSameOrAfter(shootingStart) && !isDocumentProduct) return false
    if (dealData.missions.some((assignment) => !stagesUnlockingOrderCancellation.has(assignment.stage))) return false

    return true
  }, [cancelOrder.data?.data.response?.status, cancelOrder.isSuccess, dealData, dynamicNow, isDocumentProduct, shootingStart])

  useEffect(() => {
    setDisableButton(!purchasedVisualsExist || !hasUserActiveSubscription)
  }, [purchasedVisualsExist, hasUserActiveSubscription])

  return (
    <PageActions>

      <CopyGalleryLink />

      {showCancelButton ?
        <MUIButton
          type="orangePrimary"
          onClick={() => setIsCancellationPopupOpen(true)}
        >
          {t('client_gallery.cancel_order')}
        </MUIButton>
        :
        <MUITooltip
          content={!hasUserActiveSubscription && t('only_for_subscription')}
        >
          <Box onClick={handleCheckSubscriptionClick}>
            <MUIButton
              type="defaultPrimary"
              disabled={disableButton}
              onClick={handleGoToAIGenerator}
              startIcon={<AutoAwesomeOutlined fontSize='large' />}
              endIcon={!hasUserActiveSubscription && <LockOutlinedIcon fontSize='large' />}
            >
              {t('client_gallery.ai_text_generator_tool')}
            </MUIButton>
          </Box>
        </MUITooltip>
      }

      {dealData &&
        <OrderCancellationPopup
          data={dealData}
          isOpen={isCancellationPopupOpen}
          dynamicNow={dynamicNow}
          onClickClose={() => setIsCancellationPopupOpen(false)}
        />
      }

    </PageActions>
  )
}
