import { BEIGE_600, GRAY_400, GRAY_700, GRAY_900, WHITE } from 'constants/styling/theme'
import { FileAPIEntry, FileAction, FileState } from '../FileAPI'
import React, { Fragment, useMemo } from 'react'

import Box from '@mui/material/Box'
import { CircleIcon } from 'components/common/CircleIcon'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import { LinearProgressBar } from '../../pages/PurchaseFlow/common/LinearProgressBar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UploadWrapper } from './UploadWrapper.component'
import { useTranslation } from 'react-i18next'

type CustomTranslations = { [key in FileAction]?: { [key in FileState]?: string } }

/** @interface Props for the UploadVisualItem component. */
interface Props {
  /** File item from the fileAPI entries */
  fileEntry: FileAPIEntry
  /** The label or name of the file. */
  label?: string
  /** Disables the ability to select or interact with the visual item. */
  disabledSelection?: boolean
  /** Callback function invoked when the delete button is clicked. */
  onDelete: (id: string) => void
  /** Map of custom translations */
  customTranslationMap?: CustomTranslations
}

const progressBarStates = new Set([FileState.RUNNING])

/**
 * @component
 * UploadVisualItem displays an uploaded or uploading visual item with image details and delete button.
 *
 * @example
 * <UploadVisualItem reactKey="1" type="uploading" label="example.jpg" image={{ progress: 50 }} />
 */
export const UploadVisualAPIItem: React.FC<Props> = ({
  fileEntry,
  label,
  disabledSelection,
  customTranslationMap,
  onDelete,
}) => {
  // TODO: Move "order" translations to key for upload item
  const { t, i18n } = useTranslation(['order', 'file_upload_item'])

  const showProgressBar = useMemo(() => progressBarStates.has(fileEntry.state), [fileEntry.state])

  const isError = useMemo(() => fileEntry.state === FileState.ERROR, [fileEntry.state])

  const fileStatusText = useMemo(() => {

    if (customTranslationMap?.[fileEntry.action]?.[fileEntry.state]) {
      return customTranslationMap?.[fileEntry.action]?.[fileEntry.state]
    }

    if (i18n.exists(`file_upload_item:${fileEntry.action}.${fileEntry.state}`)) {
      return t(`file_upload_item:${fileEntry.action}.${fileEntry.state}`)
    }

    return null
  }, [customTranslationMap, fileEntry.action, fileEntry.state, i18n, t])

  const handleDelete = () => {
    if (fileEntry.id && fileEntry.state !== FileState.RUNNING && !disabledSelection) {
      onDelete(fileEntry.id)
    }
  }

  return (
    <UploadWrapper type={isError ? 'error' : 'default'}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>

        {/** ICON */}
        <CircleIcon
          size="3rem"
          icon={<InsertPhotoOutlinedIcon sx={{ color: GRAY_900, fontSize: 18 }} />}
          circleColor={!isError ? BEIGE_600 : WHITE}
        />

        {/** IMAGE DETAILS */}
        <Stack flex={1} marginLeft={1}>
          {isError
            ? (
              <Typography variant='text-sm' color={GRAY_900} fontWeight={500}>
                {t('step_product.error_not_found')}
              </Typography>
            )
            : (
              <Fragment>

                {/** Name */}
                <Typography variant='text-sm' color={GRAY_900} fontWeight={500}>
                  {label ?? fileEntry.fileObject.name}
                </Typography>

                <Stack direction="row" alignItems="center" gap={0.5}>

                  {/** Size */}
                  {!!fileEntry.fileObject.size && (
                    <Fade in={true}>
                      <Box>

                        <Typography variant='text-sm' color={GRAY_700}>
                          {`${((fileEntry.fileObject.size ?? 0) / 1000000).toFixed(1)} MB`}
                        </Typography>

                        <Typography variant='text-lg' color={GRAY_400}>
                          {' •'}
                        </Typography>

                      </Box>
                    </Fade>
                  )}

                  {/** Status */}
                  <Fade in={!!fileStatusText}>
                    <Typography variant='text-sm' color={GRAY_700}>
                      {fileStatusText}
                    </Typography>
                  </Fade>

                </Stack>

              </Fragment>
            )
          }
        </Stack>

        {/** DELETE BUTTON */}
        {!disabledSelection &&
          <IconButton
            size='medium'
            disabled={fileEntry.state === FileState.RUNNING}
            onClick={handleDelete}
          >
            <DeleteOutlineRoundedIcon sx={{ color: GRAY_900, fontSize: 18 }} />
          </IconButton>
        }
      </Box>

      {/** UPLOAD PROGRESS BAR */}
      {showProgressBar &&
        <Fade in={showProgressBar}>
          <Box sx={{ width: '100%' }}>
            <LinearProgressBar isShowPercentage value={fileEntry.progress} />
          </Box>
        </Fade>
      }

    </UploadWrapper>
  )
}
