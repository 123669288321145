import { IntegerProductTuple } from 'models/product'
import { EditingCategory } from './category'
import { ProductKind } from './productTypes'

/** Describes which products based on their category and kind are integer */
export const INTEGER_PRODUCTS: IntegerProductTuple[] = [
  [EditingCategory.STAGING, ProductKind.RESIDENTIAL_STAGING],
  [EditingCategory.STAGING, ProductKind.COMMERCIAL_STAGING],
  [EditingCategory.EDITING, ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT],
  [EditingCategory.EDITING, ProductKind.PHOTO],
  [EditingCategory.EDITING, ProductKind.FLOOR_PLAN_EDITING_2D],
  [EditingCategory.EDITING, ProductKind.FLOOR_PLAN_EDITING_3D],
  [EditingCategory.EDITING, ProductKind.FLOOR_PLAN_CERTIFICATION],
]
