import { BEIGE_600, GRAY_800, GRAY_900 } from 'constants/styling/theme'
import { Collapse, Fade, IconButton } from '@mui/material'
import { FC, useState } from 'react'
import { useAuthoritiesDocuments, usePurchaseFlowConfig, usePurchaseFlowProducts } from '../../_main/contexts'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded'
import { Currency } from 'constants/misc'
import Grid from '@mui/material/Grid'
import { MUIButton } from 'components/common/MUIButton'
import { MUICheckCard } from 'components/common/MUICards'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUIDivider } from 'components/common/MUIDivider'
import { MUIInputField } from 'components/common/MUIInputField'
import { ProductCardHeader } from '../../common/ProductCardHeader'
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { ShootingCategory } from 'constants/product'
import Stack from '@mui/material/Stack'
import { StepWrapper } from '../../common/StepWrapper'
import { TransitionGroup } from 'react-transition-group'
import Typography from '@mui/material/Typography'
import { usePropertyInformationStep } from './PropertyInformationStep.context'
import { useTranslation } from 'react-i18next'

/**
 * Controller for the Property Information step in the Purchase Flow.
 * 
 * @example
 * <PropertyInformationStepController />
 */
export const PropertyInformationStepController: FC = () => {
  const { t } = useTranslation(['purchase_flow'])

  const { catalogueCurrency, selectedCategory } = usePurchaseFlowConfig()
  const { selectProduct, selectedProducts, unselectProduct } = usePurchaseFlowProducts()
  const { searchProduct } = useAuthoritiesDocuments()

  const {
    landlordFirstName,
    landlordLastName,
    landlordDateOfBirth,
    landRegisterSheetItems,
    isLandlordInfoPartiallyFilled,
    addFloor,
    removeFloor,
    onAddLandRegisterSheet,
    updateSheetList,
    updateLandRegisterSheetFloor,
    onRemoveLandRegisterSheet,
    setLandlordDateOfBirth,
    setLandlordFirstName,
    setLandlordLastName,
  } = usePropertyInformationStep()

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the landlord info fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <StepWrapper
      title={t('property_information_step.section_title')}
      subtitle={t('property_information_step.section_description')}
    >
      <Fade in={!!landRegisterSheetItems} timeout={{ enter: 600, exit: 600 }}>
        <Stack gap={2}>

          {searchProduct &&
            <MUICheckCard
              showSelectElement={true}
              isHeaderClickable={true}
              highlighted={!!selectedProducts[searchProduct.id]}
              checked={!!selectedProducts[searchProduct.id]}
              onCheck={() => {
                const selectedQuantity = selectedProducts[searchProduct.id]?.quantity
                const willBeUnchecked = selectedQuantity && selectedQuantity > 0

                if (willBeUnchecked) unselectProduct(searchProduct.id)
                else selectProduct(searchProduct, 1)
              }}
            >
              <ProductCardHeader
                disabled={false}
                product={searchProduct}
                typeKey={searchProduct.type}
                category={selectedCategory || ShootingCategory.REAL_ESTATE}
                currency={catalogueCurrency || Currency.CHF}
                quantity={undefined}
              />
            </MUICheckCard>
          }

          {/* LAND INFO */}
          <Collapse in={!searchProduct || !selectedProducts[searchProduct.id]}>

            {/** LAND REGISTER SHEET */}
            <Stack gap={2} paddingBottom="1.6rem">
              <TransitionGroup component={null}>
                {landRegisterSheetItems && landRegisterSheetItems.toArray().map(([id, value], index) => (
                  <Collapse key={id}>
                    <SectionedBorderBox
                      key={id}
                      title={
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>
                            {t('property_information_step.land_register_sheet.title')}
                          </Typography>

                          {/** REMOVE LAND REGISTER SHEET BUTTON */}
                          {index > 0 &&
                            <IconButton
                              aria-label='delete-land-register-sheet'
                              sx={{ width: '3rem', height: '3rem' }}
                              onClick={() => onRemoveLandRegisterSheet(id)}
                            >
                              <CloseRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />
                            </IconButton>
                          }
                        </Stack>
                      }
                    >

                      <Stack spacing={2} marginBottom={2} maxWidth="28%">

                        <MUIInputField
                          key={`land-register-sheet-name-${id}`}
                          required
                          isError={!value.name && showError}
                          label={t('property_information_step.land_register_sheet.input_land_register_sheet')}
                          value={value.name}
                          onBlur={handleOnInputBlur}
                          onChange={(e) => updateSheetList(id, { name: e.target.value })}
                        />

                        <MUIInputField
                          required
                          isError={!value.district && showError}
                          label={t('property_information_step.land_register_sheet.input_district')}
                          value={value.district}
                          onBlur={handleOnInputBlur}
                          onChange={(e) => updateSheetList(id, { district: e.target.value })}
                        />

                      </Stack>

                      {/** LAND REGISTER SHEET FLOOR */}
                      <Stack gap={2}>
                        <TransitionGroup component={null}>
                          {value.floor.map((floor, index) => (
                            <Collapse key={`land-floor-${id}-${index}`}>
                              <Grid container spacing={2} alignItems="flex-end">

                                <Grid item xs={4}>
                                  <MUIInputField
                                    required
                                    isError={!floor.hallway && showError}
                                    label={t('property_information_step.land_register_sheet.input_floor_hallway')}
                                    value={floor.hallway}
                                    onBlur={handleOnInputBlur}
                                    onChange={(e) => updateLandRegisterSheetFloor(id, index, { hallway: e.target.value })}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <MUIInputField
                                    label={t('property_information_step.land_register_sheet.input_floor_parcel')}
                                    value={floor.parcel}
                                    onBlur={handleOnInputBlur}
                                    onChange={(e) => updateLandRegisterSheetFloor(id, index, { parcel: e.target.value })}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  {/** ADD FLOOR */}
                                  {(
                                    (value.floor.size === 1 && index === 0) || // is first floor and only one item
                                    (value.floor.size > 1 && index === value.floor.size - 1) // is last floor and more than one items
                                  ) && (
                                      <MUIButton
                                        type='secondaryBorder'
                                        onClick={() => addFloor(id)}
                                        startIcon={<AddCircleOutlineRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />}
                                      >
                                        {t('property_information_step.land_register_sheet.add_floor')}
                                      </MUIButton>
                                    )}

                                  {/** REMOVE FLOOR - if there are more items and is not the last one. */}
                                  {value.floor.size > 1 && index !== value.floor.size - 1 && (
                                    <MUIButton
                                      type='secondaryBorder'
                                      onClick={() => removeFloor(id, index)}
                                      startIcon={<RemoveCircleOutlineRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />}
                                    >
                                      {t('property_information_step.land_register_sheet.remove_floor')}
                                    </MUIButton>
                                  )}
                                </Grid>

                              </Grid>
                            </Collapse>
                          ))}
                        </TransitionGroup>
                      </Stack>
                    </SectionedBorderBox>
                  </Collapse>
                ))}
              </TransitionGroup>
            </Stack>

            {/** BUTTON TO ADD LAND REGISTER SHEET */}
            <MUIDivider margin={8} sx={{ borderColor: BEIGE_600, borderTopColor: BEIGE_600 }}>
              <MUIButton
                type='secondaryBorder'
                onClick={onAddLandRegisterSheet}
                startIcon={<ControlPointRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />}
              >
                {t('property_information_step.land_register_sheet.add_land_register_sheet')}
              </MUIButton>
            </MUIDivider>

          </Collapse>

          {/** LANDLORD INFORMATION */}
          <SectionedBorderBox title={t('property_information_step.landlord_info.title')}>
            <Stack spacing={2}>

              <Grid container gap={2}>

                <Grid item xs={4}>
                  <MUIInputField
                    value={landlordFirstName}
                    required={isLandlordInfoPartiallyFilled}
                    isError={isLandlordInfoPartiallyFilled && !landlordFirstName && showError}
                    label={t('property_information_step.landlord_info.input_landlord_first_name')}
                    onBlur={handleOnInputBlur}
                    onChange={(e) => setLandlordFirstName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <MUIInputField
                    value={landlordLastName}
                    required={isLandlordInfoPartiallyFilled}
                    isError={isLandlordInfoPartiallyFilled && !landlordLastName && showError}
                    label={t('property_information_step.landlord_info.input_landlord_last_name')}
                    onBlur={handleOnInputBlur}
                    onChange={(e) => setLandlordLastName(e.target.value)}
                  />
                </Grid>

              </Grid>

              <Stack width="33%" gap={0.6}>

                <MUIDatePicker
                  value={landlordDateOfBirth}
                  required={isLandlordInfoPartiallyFilled}
                  isError={isLandlordInfoPartiallyFilled && !landlordDateOfBirth && showError}
                  label={t('property_information_step.landlord_info.input_date_birth')}
                  views={['year', 'month', 'day']}
                  onChange={(date) => setLandlordDateOfBirth(date)}
                />

              </Stack>

            </Stack>
          </SectionedBorderBox>

        </Stack>
      </Fade>
    </StepWrapper>
  )
}
