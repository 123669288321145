import { FC } from 'react'
import Stack from '@mui/material/Stack'

/**
 * Content for the PropertyInformation in the ConsumptionCertificateStepController.
 * 
 * @example
 * <PropertyInformation />
 */
export const PropertyInformation: FC = () => {

  return (
    <Stack>PropertyInformation</Stack>
  )
}
