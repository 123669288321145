import { usePurchaseFlowConfig, useTargetOrderUser } from '../../_main/contexts'

import { FC } from 'react'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { FileType } from 'constants/misc'
import { PowerOfAttorneyUpload } from 'components/common/PowerOfAttorneyUpload'
import { PurchaseFlowEndpoint } from 'constants/API'
import { Stack } from '@mui/material'
import { StepWrapper } from '../../common/StepWrapper'
import { useFileAPI } from 'components/common/FileAPI'
import { useTranslation } from 'react-i18next'

export const PowerOfAttorneyStepController: FC = () => {
  const { t } = useTranslation(['power_of_attorney', 'purchase_flow'])

  const { targetUser } = useTargetOrderUser()
  const { sessionId } = usePurchaseFlowConfig()

  const powerOfAttorneyUpload = useFileAPI('PF_POWER_OF_ATTORNEY', {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        PurchaseFlowEndpoint.GET_POA_UPLOAD_URL,
        { sessionId: sessionId!.toString() },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
        },
        true
      )
    },
    deleteHandler: (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_POA,
      { sessionId: sessionId!.toString() },
      true
    )
  })

  const powerOfAttorneyRepsUpload = useFileAPI('PF_POWER_OF_ATTORNEY_REPS', {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        PurchaseFlowEndpoint.GET_POA_REPS_UPLOAD_URL,
        { sessionId: sessionId!.toString() },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
        },
        true
      )
    },
    deleteHandler: (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_POA_REPS,
      { sessionId: sessionId!.toString() },
      true
    )
  })

  return (
    <StepWrapper
      title={t('purchase_flow:power_of_attorney_step.section_title')}
      subtitle={t('purchase_flow:power_of_attorney_step.section_description')}
    >
      <Stack direction="column" gap={2} alignItems="stretch" justifyContent="flex-start">

        <PowerOfAttorneyUpload
          variant="owners"
          boxStyle="elevated"
          uploadSlot={
            <FileAPIUpload
              title={t('power_of_attorney')}
              files={powerOfAttorneyUpload.allFilesArray}
              maxNumberOfFiles={1}
              isDraggingFile={false}
              acceptedFileTypes={[FileType.PDF]}
              uploadHandler={(acceptedFiles) => powerOfAttorneyUpload.uploadFiles(acceptedFiles)}
              onDelete={(fileId) => powerOfAttorneyUpload.deleteFiles([fileId])}
            />
          }
        />

        {targetUser.requiresRepresentativePowerOfAttorney &&
          <PowerOfAttorneyUpload
            variant="representatives"
            boxStyle="elevated"
            uploadSlot={
              <FileAPIUpload
                title={t('power_of_attorney_reps')}
                files={powerOfAttorneyRepsUpload.allFilesArray}
                maxNumberOfFiles={1}
                isDraggingFile={false}
                acceptedFileTypes={[FileType.PDF]}
                uploadHandler={(acceptedFiles) => powerOfAttorneyRepsUpload.uploadFiles(acceptedFiles)}
                onDelete={(fileId) => powerOfAttorneyRepsUpload.deleteFiles([fileId])}
              />
            }
          />
        }

      </Stack>
    </StepWrapper>
  )
}
