import { GRAY_400, GRAY_900 } from 'constants/styling/theme'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUIInputField } from 'components/common/MUIInputField'
import { Nullable } from 'models/helpers'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import moment from 'moment'

interface BillingPeriodItem {
  id: number
  startDate: string
  endDate: string
  consumption: string
}

interface Props {
  index: number
}

export const BillingPeriod: React.FC<Props> = ({ index }) => {
  const [beginningPeriod, setBeginningPeriod] = React.useState<Nullable<moment.Moment>>()
  const [endPeriod, setEndPeriod] = React.useState<Nullable<moment.Moment>>()
  const [consumption, setConsumption] = React.useState<number>()

  return (
    <BorderBoxWrapper padding={2} borderColor={GRAY_400} gap={2} display="flex" flexDirection="column">

      <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
        Billing period {index}
      </Typography>

      <Stack direction="row" gap={1} alignItems="center">

        <MUIDatePicker
          required
          value={beginningPeriod}
          label='Beginning'
          format='MM/YYYY'
          views={['year', 'month']}
          onChange={(newValue) => setBeginningPeriod(newValue)}
        />

        <Typography variant='text-md' color={GRAY_900} marginTop={2}>
          to
        </Typography>

        <MUIDatePicker
          required
          value={endPeriod}
          format='MM/YYYY'
          label='End'
          views={['year', 'month']}
          onChange={(newValue) => setEndPeriod(newValue)}
        />

      </Stack>

      <MUIInputField
        required
        label='Consumption'
        suffix='kWh'
        value={consumption?.toString()}
        onChange={(e) => setConsumption(parseInt(e.target.value))}
        inputElementType="number"
        inputBoxWidth="40%"
      />

    </BorderBoxWrapper>
  )
}
