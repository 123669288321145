import { useCallback, useState } from 'react'

import { ProductKind } from 'constants/product'
import constate from 'constate'

/** Enum of all available property types */
export enum MeasurementPropertyType {
  APARTMENT_SINGLE_FLOOR = 'APARTMENT_SINGLE_FLOOR',
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  APARTMENT_BUILDING = 'APARTMENT_BUILDING',
}

/** Mapping property type with extra measurement product */
export const extraProductsForMeasurementsPropertyType = new Map<MeasurementPropertyType, ProductKind>([
  [MeasurementPropertyType.APARTMENT_SINGLE_FLOOR, ProductKind.EXTRA_SINGLE_FLOOR_HOUSE_TYPE],
  [MeasurementPropertyType.SINGLE_FAMILY_HOUSE, ProductKind.EXTRA_DETACHED_HOUSE_TYPE],
])

export const [MeasurementsContextProvider, useMeasurements] = constate(() => {
  const [measurementPropertyType, setMeasurementPropertyType] = useState<MeasurementPropertyType>(MeasurementPropertyType.APARTMENT_SINGLE_FLOOR)
  const [surfaceArea, setSurfaceArea] = useState<string>('')
  const [numberOfPropertyUnit, setNumberOfPropertyUnit] = useState<number>(0)
  const [numberOfRooms, setNumberOfRooms] = useState<number>(0)
  const [numberOfFloors, setNumberOfFloors] = useState<number>(0)
  const [isBasementChecked, setIsBasementChecked] = useState<boolean>(false)
  const [isAtticChecked, setIsAtticChecked] = useState<boolean>(false)
  const [measurementComments, setMeasurementComments] = useState<string>('')

  // TODO: Implement when BE is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getMeasurementRequestData = useCallback(() => { }, [])

  return {
    surfaceArea,
    numberOfRooms,
    numberOfFloors,
    isAtticChecked,
    isBasementChecked,
    measurementComments,
    numberOfPropertyUnit,
    measurementPropertyType,
    setSurfaceArea,
    setNumberOfRooms,
    setNumberOfFloors,
    setIsAtticChecked,
    setIsBasementChecked,
    setMeasurementComments,
    setNumberOfPropertyUnit,
    setMeasurementPropertyType
  }
})
