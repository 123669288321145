export enum FileMimetype {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = '	image/png',
  IMAGE_SVG = 'image/svg+xml',
  IMAGE_ARW = 'image/x-sony-arw',
  IMAGE_CR2 = 'image/x-canon-cr2',
  IMAGE_CR3 = 'image/x-canon-cr3',
  IMAGE_CRW = 'image/x-canon-crw',
  IMAGE_DCR = 'image/x-kodak-dcr',
  IMAGE_DNG = 'image/x-adobe-dng',
  IMAGE_NEF = 'image/x-nikon-nef',
  IMAGE_RAW = 'image/x-panasonic-raw',
  IMAGE_SR2 = 'image/x-sony-sr2',
  IMAGE_SRF = 'image/x-sony-srf',
  IMAGE_ALL = 'image/*',
  PDF = 'application/pdf',
}

export enum FileType {
  JPEG = '.jpeg',
  ARW = '.arw',
  CR2 = '.cr2',
  CR3 = '.cr3',
  DNG = '.dng',
  NEF = '.nef',
  CRW = '.crw',
  RAW = '.raw',
  SR2 = '.sr2',
  SRF = '.srf',
  DCR = '.dcr',
  JPG = '.jpg',
  SVG = '.svg',
  PNG = '.png',
  PDF = '.pdf',
}

export const fileExtensionToMimetypeMap: Record<FileType, FileMimetype> = {
  [FileType.JPEG]: FileMimetype.IMAGE_JPEG,
  [FileType.JPG]: FileMimetype.IMAGE_JPEG,
  [FileType.ARW]: FileMimetype.IMAGE_ARW,
  [FileType.CR2]: FileMimetype.IMAGE_CR2,
  [FileType.CR3]: FileMimetype.IMAGE_CR3,
  [FileType.CRW]: FileMimetype.IMAGE_CRW,
  [FileType.DCR]: FileMimetype.IMAGE_DCR,
  [FileType.DNG]: FileMimetype.IMAGE_DNG,
  [FileType.NEF]: FileMimetype.IMAGE_NEF,
  [FileType.RAW]: FileMimetype.IMAGE_RAW,
  [FileType.SR2]: FileMimetype.IMAGE_SR2,
  [FileType.SRF]: FileMimetype.IMAGE_SRF,
  [FileType.SVG]: FileMimetype.IMAGE_SVG,
  [FileType.PNG]: FileMimetype.IMAGE_PNG,
  [FileType.PDF]: FileMimetype.PDF,
}

export const supportedImageFileTypes = [
  FileType.JPEG,
  FileType.JPG,
  FileType.ARW,
  FileType.CR2,
  FileType.CR3,
  FileType.CRW,
  FileType.DCR,
  FileType.DNG,
  FileType.NEF,
  FileType.RAW,
  FileType.SR2,
  FileType.SRF,
]

export const supportedDocumentFileTypes = [FileType.PDF]

export const acceptedProductVisualMimetypes: Record<string, string[]> = {
  [FileMimetype.IMAGE_JPEG]: [],
  [FileMimetype.IMAGE_ARW]: [],
  [FileMimetype.IMAGE_CR2]: [],
  [FileMimetype.IMAGE_CR3]: [],
  [FileMimetype.IMAGE_CRW]: [],
  [FileMimetype.IMAGE_DCR]: [],
  [FileMimetype.IMAGE_DNG]: [],
  [FileMimetype.IMAGE_NEF]: [],
  [FileMimetype.IMAGE_RAW]: [],
  [FileMimetype.IMAGE_SR2]: [],
  [FileMimetype.IMAGE_SRF]: [],
}

export const acceptedProductDocumentMimetypes: Record<string, string[]> = {
  [FileMimetype.IMAGE_JPEG]: [],
}

export const acceptedProductAuthoritiesDocumentMimetypes: Record<string, string[]> = {
  [FileMimetype.PDF]: [],
}

export const acceptedMimetypesAny: Record<string, string[]> = {
  ...acceptedProductVisualMimetypes,
  ...acceptedProductAuthoritiesDocumentMimetypes,
}
