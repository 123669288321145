import { ConsumptionCertificateStepController } from './ConsumptionCertificateStep.controller'
import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { PurchaseFlowStepKey } from '../../../_main/contexts'

/**
 * @component
 * Page in PurchaseFlow step for consumption certificate information.
 * 
 * @example 
 * <ConsumptionCertificateStep />
 */
export const ConsumptionCertificateStep: FC = () => {

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.CONSUMPTION_CERTIFICATE}
      isValid={true}
    >
      <ConsumptionCertificateStepController />
    </DynamicFlowStep>
  )
}
