import { FC } from 'react'
import Stack from '@mui/material/Stack'

/**
 * Content for the AdditionalInformation in the ConsumptionCertificateStepController.
 * 
 * @example
 * <AdditionalInformation />
 */
export const AdditionalInformation: FC = () => {

  return (
    <Stack>AdditionalInformation</Stack>
  )
}
