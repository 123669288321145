import { ProductKind, ProductType } from 'constants/product'
import { BasicFloorPlanLinks, CommercialRenderingLinks, DroneLinks, DroneVideoLinks, FloorPlan3DLinks, GroundDroneVideoLinks, GroundLinks, GroundVideoLinks, HightDefinitionTourLinks, MatterportFloorPlanLinks, PhotoExtractionLinks, PrestigeLinks, PrestigeVideoLinks, Rendering360Links, ResidentialRenderingLinks, SlideshowLinks, Staging360Links, StandardStagingLinks, TeaserVideoLinks } from 'constants/staticLinks'

export const getProductDetailsPage = (language: string, productKind: ProductKind, productType: ProductType) => {
  const languageUpperCase = language.toUpperCase()
  if (languageUpperCase in getProductKindLinks(productKind, productType)) {
    return getProductKindLinks(productKind, productType)[languageUpperCase]
  } else if ('EN' in getProductKindLinks(productKind, productType)) {
    return getProductKindLinks(productKind, productType).EN
  }

  return null
}

interface ProductKindLinks {
  [key: string]: string
}

export const getProductKindLinks = (productKind: ProductKind, productType: ProductType): ProductKindLinks => {
  switch (productKind) {
    case ProductKind.GROUND_PHOTO:
      return GroundLinks

    case ProductKind.DRONE_PHOTO:
      return DroneLinks

    case ProductKind.GROUND_PHOTO_PRESTIGE:
      return PrestigeLinks

    case ProductKind.STANDARD_GROUND_VIDEO:
      return GroundVideoLinks

    case ProductKind.STANDARD_GROUND_AND_DRONE_VIDEO:
      return GroundDroneVideoLinks

    case ProductKind.STANDARD_DRONE_VIDEO:
      return DroneVideoLinks

    case ProductKind.TEASER_VIDEO:
      return TeaserVideoLinks

    case ProductKind.PRESTIGE_GROUND_AND_DRONE_VIDEO:
      return PrestigeVideoLinks

    case ProductKind.MATTERPORT_PHOTO:
      return PhotoExtractionLinks

    case ProductKind.HD:
      return HightDefinitionTourLinks

    case ProductKind.SLIDESHOW:
      return SlideshowLinks

    case ProductKind.MOBILE_APPLICATION_FLOOR_PLAN:
      return BasicFloorPlanLinks

    case ProductKind.MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT:
    case ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT:
      return MatterportFloorPlanLinks

    case ProductKind.FLOOR_PLAN_EDITING_3D:
      return FloorPlan3DLinks

    case ProductKind.FLOOR_PLAN_CERTIFICATION:
      // TODO: Add Floor Plan Certification links
      return {}

    case ProductKind.RESIDENTIAL_STAGING:
    case ProductKind.COMMERCIAL_STAGING:
      if (productType === ProductType.PHOTO) {
        return StandardStagingLinks // type: PHOTO, kind: Residential staging + Commercial staging
      } else if (productType === ProductType.STAGING_360) {
        return Staging360Links // type: 360, kind: Residential staging + Commercial staging
      }
      return {}

    case ProductKind.COMMERCIAL_RENDERING:
      if (productType === ProductType.PHOTO) {
        return CommercialRenderingLinks // type: PHOTO, kind: Commercial Rendering
      } else if (productType === ProductType.STAGING_360) {
        return Rendering360Links // type: 360, kind: Commercial Rendering
      }
      return {}

    case ProductKind.RESIDENTIAL_RENDERING:
      if (productType === ProductType.PHOTO) {
        return ResidentialRenderingLinks // type: PHOTO, kind: Residential Rendering
      } else if (productType === ProductType.STAGING_360) {
        return Rendering360Links // type: 360, kind: Residential Rendering
      }
      return {}

    default:
      return {}
  }
}
