import { Box, Stack } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'

import { AssignmentDocumentDTO } from 'models/assignment'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { DocumentUploadType } from 'constants/documents'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { FilterTab } from 'components/common/FilterTab'
import JSZip from 'jszip'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { Nullable } from 'models/helpers'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { saveAs } from 'file-saver'
import { useClientGallery } from '../_main/contexts/ClientGallery.context'
import { useGetAssignmentDocuments } from 'dataQueries/assignmentDocuments.query'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery documents component
 * 
 * @example
 * <ClientGalleryDocuments />
 */
export const ClientGalleryDocuments: FC = () => {
  const { t } = useTranslation(['gallery'])
  const { assignmentId } = useClientGallery()

  const getAssignmentOutputDocuments = useGetAssignmentDocuments(assignmentId, DocumentUploadType.OUTPUT)
  const assignmentDocumentsData = useMemo(() => getAssignmentOutputDocuments.data?.data || [], [getAssignmentOutputDocuments.data])

  const [selectedDoc, setSelectedDoc] = useState<Nullable<AssignmentDocumentDTO>>()

  useEffect(() => {
    if (assignmentDocumentsData.length) {
      setSelectedDoc(assignmentDocumentsData[0])
    }
  }, [assignmentDocumentsData])

  const downloadAllFiles = async () => {
    const zip = new JSZip()
    const folder = zip.folder(`${assignmentId}`)

    for (const doc of assignmentDocumentsData) {
      const response = await fetch(doc.signedUrl.signedURL)
      const blob = await response.blob()
      folder?.file(`${doc.signedUrl.filename}`, blob)
    }

    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, `${assignmentId}.zip`)
  }

  return (
    <DynamicQueryContent query={getAssignmentOutputDocuments}>
      <Stack width="100%" marginTop="3rem" gap={4} display="flex" flexDirection="row" justifyContent="space-between">

        {assignmentDocumentsData.length > 1 &&
          <Box height="100%" flex="1 0 300px" position="sticky">
            <SectionedBorderBox title={t('provided_files')}>

              <Stack gap="1rem">
                {assignmentDocumentsData.map((doc, index) => {
                  return (
                    <FilterTab
                      key={doc.name}
                      isActive={selectedDoc?.name === doc.name}
                      height='4rem'
                      label={doc.signedUrl.filename}
                      onClick={() => setSelectedDoc(doc)}
                    />
                  )
                })}

              </Stack>

              <MUIDivider margin={12} />

              <MUIButton
                fullWidth
                type="secondaryBorder"
                size="md"
                startIcon={<CloudDownloadOutlinedIcon fontSize="medium" />}
                onClick={downloadAllFiles}
              >
                {t('download_all_files')}
              </MUIButton>

            </SectionedBorderBox>
          </Box>
        }

        {assignmentDocumentsData.length &&
          <Stack flex="1 1 75%">
            <iframe
              src={selectedDoc?.signedUrl.signedURL}
              width="100%"
              height="600px"
              style={{ border: 'none' }}
              title={t('pdf_preview')}
            />
          </Stack>
        }

      </Stack>
    </DynamicQueryContent>
  )
}
