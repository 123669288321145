import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { usePurchaseFlowConfig, usePurchaseFlowProducts, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import Box from '@mui/material/Box'
import { FeatureFlag } from 'utils/featureFlags'
import { FloorPlanLogoUpload } from 'components/pages/PurchaseFlow/common/FloorPlanLogoUpload'
import { FloorPlanToggleSetting } from './FloorPlanToggleSetting.component'
import GoogleMap from 'components/common/GoogleMap/GoogleMap'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import { InputWrap } from 'components/common/InputWrap'
import { Language } from 'translations/Language'
import { MUICheckCard } from 'components/common/MUICards'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUITooltip } from 'components/common/MUITooltip'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import { Stack } from '@mui/material'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import Typography from '@mui/material/Typography'
import i18n from 'translations/i18n'
import { isAddressValid } from 'utils/validators'
import { isEditingCategory } from 'utils/validators'
import { useDraggingFile } from 'utils/hooks'
import { useFlag } from '@unleash/proxy-client-react'
import { useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'

export enum FloorPlanFormat {
  JPG = 'JPG',
  PDF = 'PDF',
}

/**
 * @component
 * FloorPlanLayout displays the layout options for floor plans.
 * 
 * @example
 * <FloorPlanLayout />
 */
export const FloorPlanLayout: React.FC = () => {
  const { t } = useTranslation(['purchase_flow', 'order', 'step_address'])

  const { targetUser } = useTargetOrderUser()
  const { selectedCategory } = usePurchaseFlowConfig()
  const allowCompassAndDisclaimer = useFlag(FeatureFlag.ALLOW_COMPASS_AND_DISCLAIMER)

  const {
    showRoomNames,
    setShowRoomNames,
    showScalebar,
    setShowScalebar,
    showCompass,
    setShowCompass,
    outputLanguage,
    setOutputLanguage,
    floorPlanFormat,
    setFloorPlanFormat,
    isActiveDimensions,
    setIsActiveDimensions,
    showEachRoomDimensions,
    setShowEachRoomDimensions,
    showDimensionChain,
    setShowDimensionChain,
    showRoomArea,
    setShowRoomArea,
    isActiveLogo,
    setIsActiveLogo,
    showDisclaimer,
    disclaimerText,
    setShowDisclaimer,
    setDisclaimerText,
    setCompassAddress,
    compassAddress,
    is3DRenderType,
  } = useFloorPlanConfig()

  const { floorPlanProduct } = usePurchaseFlowProducts()

  const isDraggingFile = useDraggingFile()

  const addressIsValid = useMemo(() => isAddressValid(compassAddress), [compassAddress])

  const formatToggleValues: ToggleFilterValue<FloorPlanFormat>[] = useMemo(
    () => {
      let availableformat: FloorPlanFormat[] = [FloorPlanFormat.JPG]

      if (!is3DRenderType) availableformat.push(FloorPlanFormat.PDF)

      return availableformat.map((format) => {
        return {
          value: format,
          displayName: format,
        }
      })
    }, [is3DRenderType]
  )

  const toggleFormat = useCallback((format: FloorPlanFormat) => {
    setFloorPlanFormat((prev) => {
      const updated = { ...prev }
      const newSelection = updated[format]

      if (newSelection) delete updated[format]
      else updated[format] = format

      return updated
    })
  }, [setFloorPlanFormat])


  return (
    <SectionedBorderBox title={t('floor_plan_step.layout.title')}>
      <Box display="flex" flexDirection="column" gap={1.6}>

        <MUICheckCard
          isHeaderClickable
          checked={showRoomNames}
          onCheck={() => setShowRoomNames((prevState) => !prevState)}
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.layout.add_room_name')}
          </Typography>
        </MUICheckCard>

        <MUICheckCard
          checked={isActiveDimensions}
          onCheck={() => setIsActiveDimensions((prevState) => !prevState)}
          expandableContent={(
            <Stack gap=".8rem">
              <FloorPlanToggleSetting
                title={t('floor_plan_step.layout.showcase_dimension_each_room')}
                value={showEachRoomDimensions}
                setValue={setShowEachRoomDimensions}
              />

              <FloorPlanToggleSetting
                title={t('floor_plan_step.layout.showcase_dimension_chains')}
                value={showDimensionChain}
                setValue={setShowDimensionChain}
              />

              <FloorPlanToggleSetting
                title={t('floor_plan_step.layout.showcase_room_area')}
                value={showRoomArea}
                setValue={setShowRoomArea}
              />
            </Stack>
          )}
        >
          <Stack gap=".6rem" flexDirection="row" alignItems="center">
            <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
              {t('floor_plan_step.layout.add_dimensions')}
            </Typography>

            <MUITooltip
              content={<Trans t={t} i18nKey="floor_plan_step.layout.dimension_tooltip"><span>{'<'}</span></Trans>}
              type="primary"
            >
              <HelpOutlineRoundedIcon sx={{ color: GRAY_900, paddingTop: '.3rem' }} />
            </MUITooltip>
          </Stack>
        </MUICheckCard>

        <MUICheckCard
          isHeaderClickable
          checked={showScalebar}
          onCheck={() => setShowScalebar((prevState) => !prevState)}
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.layout.add_scalebar')}
          </Typography>
        </MUICheckCard>

        <MUICheckCard
          isHeaderClickable
          checked={showCompass}
          onCheck={() => setShowCompass((prevState) => !prevState)}
          expandableContent={allowCompassAndDisclaimer && showCompass &&
            (selectedCategory && isEditingCategory(selectedCategory)) &&
            <Stack gap=".8rem">

              <Typography variant='text-sm' fontWeight={500} color={GRAY_700}>
                {t('floor_plan_step.layout.compass_address')}
              </Typography>

              <Box>
                {/* Google Map for Address Selection */}
                <GoogleMap
                  height={'40rem'}
                  inputPosition="above"
                  language={i18n.language}
                  countryCode={targetUser?.country}
                  initialAddress={compassAddress?.formatted_address}
                  initialMarker={compassAddress?.geometry.location}
                  handleChange={(newGooglePlace, isInitialLookup) => {
                    if (!isInitialLookup) setCompassAddress(newGooglePlace)
                  }}
                  onInputChange={e => setCompassAddress(null)}
                  error={showCompass && (!compassAddress || !addressIsValid) ? t('order:step_address.address_not_selected') : undefined}
                />
              </Box>

            </Stack>
          }
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.layout.add_compass')}
          </Typography>
        </MUICheckCard>

        <MUICheckCard
          isHeaderClickable
          checked={isActiveLogo}
          onCheck={() => setIsActiveLogo((prevState) => !prevState)}
          expandableContent={!!floorPlanProduct &&
            <FloorPlanLogoUpload
              product={floorPlanProduct}
              isDraggingFile={isDraggingFile}
            />
          }
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.layout.add_company_logo')}
          </Typography>


        </MUICheckCard>


        {allowCompassAndDisclaimer &&
          <MUICheckCard
            isHeaderClickable
            checked={showDisclaimer}
            onCheck={() => setShowDisclaimer((prevState) => !prevState)}
            expandableContent={
              <Box>
                <InputWrap label={''} error={showDisclaimer && disclaimerText === '' ? t('floor_plan_step.layout.disclaimer_error') : undefined}>
                  <textarea
                    placeholder={t('floor_plan_step.layout.disclaimer_placeholder')}
                    value={disclaimerText}
                    onChange={(e) => setDisclaimerText(e.target.value)}
                    maxLength={200}>
                  </textarea>
                </InputWrap>
              </Box>
            }
          >
            <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
              {t('floor_plan_step.layout.disclaimer')}
            </Typography>
          </MUICheckCard>
        }

        <SectionedBorderBox title={t('floor_plan_step.layout.format')}>
          <SelectableButtonsGrid
            showCheckbox
            preventUnselect
            activeButtonClickable
            values={formatToggleValues}
            selectedValue={Object.values(floorPlanFormat)}
            onSelect={format => toggleFormat(format)}
            onUnselect={format => toggleFormat(format)}
          />
        </SectionedBorderBox>

        <SectionedBorderBox title={t('floor_plan_step.layout.output_lang')}>
          <Box width="50%">

            <MUIDropdown
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  type={DropdownInputTypes.SELECTOR}
                  value={t(`language:${outputLanguage.toUpperCase()}`, outputLanguage)}
                  onClick={action}
                />
              )}
            >
              {Object.values(Language)?.map(lang => (
                <MUIDropdownItem key={lang} onClick={() => setOutputLanguage(lang)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`language:${lang.toUpperCase()}`, lang)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>

          </Box>
        </SectionedBorderBox>

      </Box>
    </SectionedBorderBox>
  )
}
