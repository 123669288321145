import { FileAction, FileState, useFileAPI } from 'components/common/FileAPI'
import React, { useCallback, useMemo } from 'react'
import { UploadOrderDocumentType, useSubmitGalleryPowerOfAttorney } from 'dataQueries'

import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { FileType } from 'constants/misc'
import { MUIButton } from 'components/common/MUIButton'
import { OrderEndpoint } from 'constants/API'
import { PowerOfAttorneyUpload } from 'components/common/PowerOfAttorneyUpload'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Renders the component for uploading power of attorney documents in the client gallery.
 * 
 * @example
 * <ClientGalleryUploadPowerAttorney />
 */
export const ClientGalleryUploadPowerAttorney: React.FC = () => {
  const { t } = useTranslation(['power_of_attorney', 'common'])
  const { dealData } = useGalleryDeal()
  const { spawnErrorToast, spawnSuccessToast } = useSnackbar()

  const submitMutation = useSubmitGalleryPowerOfAttorney()

  const powerOfAttorneyUpload = useFileAPI('POWER_OF_ATTORNEY', {
    uploadUrlResolver: (fileEntry, api) => api.post(
      OrderEndpoint.GET_POA_UPLOAD_URL,
      {
        orderId: dealData!.id.toString(),
      },
      {
        type: UploadOrderDocumentType.POWER_OF_ATTORNEY,
        filename: fileEntry.fileObject.name,
        contentType: fileEntry.fileObject.type,
      },
      true
    ),
    deleteHandler: (fileEntry, api) => api.delete(
      OrderEndpoint.DELETE_UPLOADED_POA,
      {
        orderId: dealData!.id.toString(),
        filename: fileEntry.gcFilename || '',
      },
      true,
      {
        params: {
          type: UploadOrderDocumentType.POWER_OF_ATTORNEY
        }
      }
    )
  })

  const powerOfAttorneyRepsUpload = useFileAPI('POWER_OF_ATTORNEY_REPS', {
    uploadUrlResolver: (fileEntry, api) => api.post(
      OrderEndpoint.GET_POA_UPLOAD_URL,
      {
        orderId: dealData!.id.toString(),
      },
      {
        type: UploadOrderDocumentType.POWER_OF_ATTORNEY,
        filename: fileEntry.fileObject.name,
        contentType: fileEntry.fileObject.type,
      },
      true
    ),
    deleteHandler: (fileEntry, api) => api.delete(
      OrderEndpoint.DELETE_UPLOADED_POA,
      {
        orderId: dealData!.id.toString(),
        filename: fileEntry.gcFilename || '',
      },
      true,
      {
        params: {
          type: UploadOrderDocumentType.POWER_OF_ATTORNEY
        }
      }
    )
  })

  const isDisabled = useMemo(() => {
    const poaFiles = Object.values(powerOfAttorneyUpload.files)
    const poaRepFiles = Object.values(powerOfAttorneyRepsUpload.files)

    if (poaFiles.length !== 1) return true
    if (poaFiles[0].action !== FileAction.UPLOAD || poaFiles[0].state !== FileState.SUCCESS) return true

    if (!dealData?.powerOfAttorney.requiresPowerOfAttorneyRepresentatives) return false

    if (poaRepFiles.length !== 1) return true
    if (poaRepFiles[0].action !== FileAction.UPLOAD || poaRepFiles[0].state !== FileState.SUCCESS) return true

    return false
  }, [dealData?.powerOfAttorney.requiresPowerOfAttorneyRepresentatives, powerOfAttorneyRepsUpload.files, powerOfAttorneyUpload.files])

  const handleSubmit = useCallback(() => {
    if (isDisabled) return
    if (!dealData?.id) return

    const poaFilename = Object.values(powerOfAttorneyUpload.files)[0]?.gcFilename
    const poaRepFilename = Object.values(powerOfAttorneyRepsUpload.files)[0]?.gcFilename

    if (!poaFilename) return
    if (!!dealData?.powerOfAttorney.requiresPowerOfAttorneyRepresentatives && !poaRepFilename) return

    submitMutation.mutate(
      {
        orderId: dealData.id,
        powerOfAttorneyFilename: poaFilename,
        powerOfAttorneyRepresentativesFilename: poaRepFilename || undefined,
      },
      {
        onSuccess: () => {
          spawnSuccessToast(t('submit_success'))
        },
        onError: (error) => {
          spawnErrorToast(t('submit_error'), { error: error.request })
        }
      }
    )

  }, [dealData?.id, dealData?.powerOfAttorney.requiresPowerOfAttorneyRepresentatives, isDisabled, powerOfAttorneyRepsUpload.files, powerOfAttorneyUpload.files, spawnErrorToast, spawnSuccessToast, submitMutation, t])

  return (
    <SectionedBorderBox title={t('missing_documents')} marginTop={2}>
      <Stack gap={2}>

        {dealData?.powerOfAttorney.requiresPowerOfAttorney &&
          <PowerOfAttorneyUpload
            boxTitle={t('upload_box_title_owners')}
            variant="owners"
            badgeText={t('awaiting_signature')}
            boxStyle="bordered"
            uploadSlot={
              <FileAPIUpload
                title={t('power_of_attorney')}
                files={powerOfAttorneyUpload.allFilesArray}
                acceptedFileTypes={[FileType.PDF]}
                isDraggingFile={false}
                uploadHandler={(acceptedFiles) => powerOfAttorneyUpload.uploadFiles(acceptedFiles)}
                onDelete={(fileId) => powerOfAttorneyUpload.deleteFiles([fileId])}
              />
            }
          />
        }

        {dealData?.powerOfAttorney.requiresPowerOfAttorneyRepresentatives &&
          <PowerOfAttorneyUpload
            boxTitle={t('upload_box_title_representatives')}
            variant="representatives"
            badgeText={t('awaiting_signature')}
            boxStyle="bordered"
            uploadSlot={
              <FileAPIUpload
                title={t('power_of_attorney_representatives')}
                files={powerOfAttorneyRepsUpload.allFilesArray}
                acceptedFileTypes={[FileType.PDF]}
                isDraggingFile={false}
                uploadHandler={(acceptedFiles) => powerOfAttorneyRepsUpload.uploadFiles(acceptedFiles)}
                onDelete={(fileId) => powerOfAttorneyRepsUpload.deleteFiles([fileId])}
              />
            }
          />
        }

        <MUIButton
          sx={{ alignSelf: 'flex-end' }}
          isLoading={submitMutation.isPending}
          disabled={isDisabled}
          onClick={() => handleSubmit()}
        >
          {t('common.Submit')}
        </MUIButton>

      </Stack>
    </SectionedBorderBox>
  )
}
