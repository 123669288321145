import { useMemo, useState } from 'react'

import constate from 'constate'

/** Enum of available information types */
export enum InformationType {
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  HEATING_SYSTEM = 'HEATING_SYSTEM',
  CONSUMPTION_INFORMATION = 'CONSUMPTION_INFORMATION',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

export const [ConsumptionCertificateStepContextProvider, useConsumptionCertificateStep] = constate(() => {
  const [infoType, setInfoType] = useState<InformationType>(InformationType.PROPERTY_INFORMATION)

  // TODO: Implement with page content detail
  const isPropertyInformationValid = useMemo(() => true, [])
  const isHeatingSystemValid = useMemo(() => true, [])
  const isConsumptionInformationValid = useMemo(() => true, [])
  const isAdditionalInformationValid = useMemo(() => true, [])

  return {
    infoType,
    setInfoType,
    isPropertyInformationValid,
    isHeatingSystemValid,
    isConsumptionInformationValid,
    isAdditionalInformationValid,
  }
})
