import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { GRAY_400, GRAY_700, GRAY_900 } from 'constants/styling/theme'

import AddIcon from '@mui/icons-material/Add'
import { BillingPeriod } from './BillingPeriod'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUIDivider } from 'components/common/MUIDivider'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIRadio } from 'components/common/MUIRadio'
import { Nullable } from 'models/helpers'
import React from 'react'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import moment from 'moment'

enum FuelType {
  HEATING_OIL = 'HEATING_OIL',
  ORGANIC_OIL = 'ORGANIC_OIL',
  NATURAL_GAS = 'NATURAL_GAS',
  BIOGAS = 'BIOGAS',
  LIQUID_GAS = 'LIQUID_GAS',
  HARD_COAL = 'HARD_COAL',
  LIGNITE = 'LIGNITE',
  PIECE_WOOD = 'PIECE_WOOD',
  WOOD_CHIPS = 'WOOD_CHIPS',
  WOOD_PELLETS = 'WOOD_PELLETS',
  NEAR_DISTRICT_HEATING = 'NEAR_DISTRICT_HEATING',
  ELECTRICITY = 'ELECTRICITY',
}

enum HotWaterType {
  CENTRALLY_STANDARD = 'CENTRALLY_STANDARD', // Heating generates hot water centrally standard
  CONTINUES_OR_BOILER_WW = 'CONTINUES_OR_BOILER_WW', // Continues water heater or boiler WW
  DESCENTRALISED_ELECTRIC = 'DESCENTRALISED_ELECTRIC', // Decentralised electric
  INSTANTANEOUS_OR_SOLAR_POWERED = 'INSTANTANEOUS_OR_SOLAR_POWERED', // Instantaneous water or boiler with solar power
  KNOWN_CONSUMPTION = 'KNOWN_CONSUMPTION', // I know the hot water consumption in kWh
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN', // Plant does not contain hot water production
}

enum UnitCaptureType {
  KWH = 'KWH',
  KG = 'KG',
}

interface Props {
  index: number
}

export const HeatingSystem: React.FC<Props> = ({ index }) => {
  const [fuelType, setFuelType] = React.useState<FuelType>()
  const [hotWaterType, setHotWaterType] = React.useState<HotWaterType>()
  const [unitCaptureType, setUnitCaptureType] = React.useState<UnitCaptureType>()
  const [yearOfManufacture, setYearOfManufacture] = React.useState<Nullable<moment.Moment>>()

  return (
    <SectionedBorderBox title={`Heating system #${index}`}>

      <Stack alignItems="flex-start" gap={2}>

        <MUIDatePicker
          required
          label='Generator year of manufacture'
          format='YYYY'
          views={['year']}
          value={yearOfManufacture}
          onChange={(newValue) => setYearOfManufacture(newValue)}
        />

        <Stack direction="row" flexWrap="wrap" width="100%" gap={2}>

          <Box width={{ xs: '100%', sm: '40rem', lg: '50rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, onClick) => (
                <MUIDropdownInput
                  // required
                  value={fuelType}
                  type={DropdownInputTypes.SELECTOR}
                  label="Fuel"
                  onClick={onClick}
                />
              )}
            >
              {Object.values(FuelType).map((fuelTypeItem) => (
                <MUIDropdownItem key={fuelTypeItem} onClick={() => setFuelType(fuelTypeItem)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {fuelTypeItem}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Box>

          <Box width={{ xs: '100%', sm: '40rem', lg: '50rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, onClick) => (
                <MUIDropdownInput
                  // required
                  value={hotWaterType}
                  type={DropdownInputTypes.SELECTOR}
                  label="Hot water"
                  onClick={onClick}
                />
              )}
            >
              <Box>
                {Object.values(HotWaterType).map((hotWaterTypeItem) => (
                  <MUIDropdownItem key={hotWaterTypeItem} onClick={() => setHotWaterType(hotWaterTypeItem)}>
                    <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                      {hotWaterTypeItem}
                    </Typography>
                  </MUIDropdownItem>
                ))}
              </Box>
            </MUIDropdown>
          </Box>

        </Stack>

        <Stack gap={0.6}>

          <Typography variant='text-sm' fontWeight='medium' color={GRAY_700}>
            Unit capture
          </Typography>

          {Object.values(UnitCaptureType).map((unitCaptureTypeItem) => (
            <Stack key={unitCaptureTypeItem} gap=".8rem" flexDirection="row">
              <MUIRadio
                checked={unitCaptureType === unitCaptureTypeItem}
                onChange={() => setUnitCaptureType(unitCaptureTypeItem)}
              />

              <Typography variant="text-md" fontWeight={500} color={GRAY_900}>
                {unitCaptureTypeItem}
              </Typography>
            </Stack>
          ))}

        </Stack>

      </Stack>

      <Stack gap={0.6} marginTop={2}>

        <Typography variant="text-md" fontWeight={600} color={GRAY_900}>
          Consumption data
        </Typography>
        <Typography variant="text-sm" color={GRAY_700}>
          Please note that the billing periods cover at least three consecutive years, including the most recent billing period. Enter a 4th billing period to reflect three full years.
        </Typography>

        <MUIDivider margin={8} />

        {/* Billing period items */}
        <Box flexGrow={1}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <BillingPeriod index={1} />
            </Grid>

            {/** Add new billing period */}
            <Grid item xs={12} sm={6}>
              <BorderBoxWrapper
                borderColor={GRAY_400}
                sx={{
                  padding: 2,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  borderStyle: 'dashed',
                  justifyContent: 'center',
                }}
              >
                <MUIButton type='secondaryNoBorder' size='md' startIcon={<AddIcon fontSize='large' sx={{ GRAY_700 }} />}>
                  New billing period
                </MUIButton>
              </BorderBoxWrapper>
            </Grid>

          </Grid>
        </Box>

      </Stack>

    </SectionedBorderBox>
  )
}
