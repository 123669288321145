import { EntityKeys, QueryType } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { DocumentUploadType } from 'constants/documents'
import { AssignmentDocumentDTO } from 'models/assignment'
import { useAPI } from 'utils/API'

enum Endpoints {
  GET_ASSIGNMENT_DOCUMENTS = '/assignment/{assignmentId}/documents?type={type}',
}

// QUERIES
export function useGetAssignmentDocuments(assignmentId: string, type: DocumentUploadType) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET, { assignmentId }],
    queryFn: () => api.get<AssignmentDocumentDTO[]>(
      Endpoints.GET_ASSIGNMENT_DOCUMENTS,
      {
        assignmentId,
        type,
      },
      true
    ),
    enabled: !!assignmentId,
  })
}
