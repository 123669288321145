import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Stack, Typography } from '@mui/material'

import { FC } from 'react'
import { MUICheckbox } from 'components/common/MUICheckBox'

/**
 * @interface Props MeasurementCheckboxItem properties
 */
export interface Props {
  /** Title of checkbox item */
  title: string,
  /** Description of checkbox item */
  description: string,
  /** Whether the checkbox is checked */
  checked: boolean,
  /** Function to run when the checkbox is clicked */
  onClick: () => void
}

/**
 * @component
 * Checkbox item for measurement page.
 * 
 * @example 
 * <MeasurementCheckboxItem />
 */
export const MeasurementCheckboxItem: FC<Props> = ({
  title,
  description,
  checked,
  onClick
}) => (
  <Stack flexDirection="row" gap=".8rem" alignItems="flex-start" onClick={onClick}>
    <MUICheckbox checked={checked} sx={{ padding: '.5rem 0' }} />
    <Stack>
      <Typography variant="text-md" color={GRAY_900}>{title}</Typography>

      {description &&
        <Typography variant="text-sm" color={GRAY_700}>{description}</Typography>
      }
    </Stack>
  </Stack>
)
